import { Button as Btn } from "@mui/material"

const Button = (props) => {

  const basicStyle = {
    border: props.border ? props.border : '1px solid rgba(68, 68, 68, 0.15)',
    borderRadius: props.borderRadius ? props.borderRadius : '5px',
    width: props.width ? props.width : '7.5rem',
    height: '2.75rem',

    fontSize: props.fontSize ? props.fontSize : '0.875rem',
    marginRight: props.marginRight ? props.marginRight : '',
    marginLeft: props.marginLeft ? props.marginLeft : '',
    marginBottom: props.marginBottom ? props.marginBottom : '',
    marginTop: props.marginTop ? props.marginTop : '',
    margin: props.margin ? props.margin : '',
  }
  const whiteStyle = {
    ...basicStyle,
    backgroundColor: '#FFFFFF',
    color: '#444444',
    fontWeight: '500',

  }
  const blueStyle = {
    ...basicStyle,
    backgroundColor: '#005AE1',
    color: '#FFFFFF',
    fontWeight: '400',
  }

  const customStyle = {
    /* backgroundColor와 color 필수 */
    ...basicStyle,
    backgroundColor: props.backgroundColor,
    color: props.color,
    fontWeight: props.fontWeight ? props.fontWeight : '400',
  }

  const getTheme = () => {
    if (props.theme === 'blue') {
      return blueStyle
    } else if (props.theme === 'white') {
      return whiteStyle
    } else if (props.theme === 'custom') {
      return customStyle
    }
  }


  return <Btn style={getTheme()} disabled={props.disabled} onClick={props.onClick}>{props.btnName}</Btn>
}

export default Button