import { FooterInfoBox } from "./style";

const FooterInfo = (props) => {
  const { isFreePass, isLink, linkCnt, remainDay } = props;

  return (
    <FooterInfoBox>
      {isFreePass
        ? `무료패스권을 사용해 입장합니다.`
        : isLink
        ? `타지점 출입 횟수가 ${linkCnt || 0}회 남았습니다.`
        : `D-${remainDay}`}
    </FooterInfoBox>
  );
};

export default FooterInfo;
