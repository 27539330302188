// 초기화
const initialState = {
  show: false,
  titleText: "",
  bodyText: "",
  callback: null,
  onBtnOk: undefined,
  onBtnNo: undefined,
};

// 리듀서 작성
// function global_loading(state = initialStete, action) {
export const alert_state = (state = initialState, action) => {
  switch (action.type) {
    case "alert/GLOBAL_STATE/ON": {
      return { ...state, stores: action.payload };
    }
    case "alert/GLOBAL_STATE/OFF":
      return { stores: initialState };
    default:
      return state;
  }
};
