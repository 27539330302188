import { composeWithDevTools } from "redux-devtools-extension";

import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./modules/reducers";
import rootSaga from "./modules/sagas";

import { persistStore } from "redux-persist";
// import { PersistGate } from "redux-persist/integration/react";

const sagaMiddleware = createSagaMiddleware();
const initialState = {};

export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
