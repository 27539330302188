import LoginForm from "../../components/LoginForm/LoginForm";
import loginLogo from "assets/logos/loginLogo.svg";

const LoginTemplete = ({ setLoginInfo }) => {
  return (
    <div
      style={{
        boxSizing: "border-box",
        // border: "1px solid orange",

        background:
          "linear-gradient(337.81deg, rgba(153, 6, 190, 0.2) 0.98%, rgba(0, 26, 255, 0.2) 97.08%), linear-gradient(0deg, #26202E, #26202E), #FFFFFF",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // border: "1px solid red",
        }}
      >
        <img
          alt="랩스로고"
          src={loginLogo}
          style={{
            marginTop: "6.25rem",
            width: "200px",
          }}
        />
        <LoginForm setLoginInfo={setLoginInfo} />
      </div>
    </div>
  );
};

export default LoginTemplete;
