const Label = (props) => {
  const { labelText, textAlign, justifyContent } = props;

  if (labelText) {
    return (
      <label
        style={{
          width: props.width,
          textAlign,
          display: "flex",
          padding: props.padding ? props.padding : "0rem",
          alignItems: props.alignItems ? props.alignItems : "center",
          // backgroundColor: "#fff",

          fontSize: props.fontSize ? props.fontSize : "0.938rem",
          fontWeight: props.fontWeight ? props.fontWeight : "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          color: props.color ? props.color : props.disabled ? "#999" : "#554646",
          justifyContent: justifyContent || "center",
          marginTop: props.marginTop ? props.marginTop : "0rem",
          marginLeft: props.marginLeft ? props.marginLeft : "0rem",
          marginRight: props.marginRight ? props.marginRight : "0rem",
          marginBottom: props.marginBottom ? props.marginBottom : "0rem",
          whiteSpace: props.whiteSpace,
          transform: props.transform,
          zIndex: props.zIndex,
          cursor: props.cursor,

        }}
        onClick={props.onClick}
      >
        {labelText}&nbsp;
      </label>
    );
  }
  return null;
};

Label.defaultProps = {
  textAlign: "left",
};

export default Label;
