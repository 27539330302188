import Cookies from "cookies-js";

const COOKIE_OPTIONS = {
  secure: process.env.NODE_ENV === "production",
  path: "/",
};

export const cookieManager = {
  getTokens: () => ({
    access: Cookies.get("accessToken"),
    refresh: Cookies.get("refreshToken"),
  }),

  setTokens: (tokens) => {
    Cookies.set("accessToken", tokens.access, COOKIE_OPTIONS);
    Cookies.set("refreshToken", tokens.refresh, COOKIE_OPTIONS);
  },

  removeTokens: () => {
    Cookies.expire("accessToken");
    Cookies.expire("refreshToken");
  },

  isLogin: () => Boolean(Cookies.get("accessToken")),
};
