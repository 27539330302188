import lockerIcon from "assets/icons/locker.png";
import clothIcon from "assets/icons/cloth.png";
import gxIcon from "assets/icons/gx.png";
import linkIcon from "assets/icons/link.png";
import freePassIcon from "assets/icons/freePass.png";
import { CountCircle, IconContainer, IconWrapper } from "./style";

const SubInfoItem = (props) => {
  const { itemName, gender, isUse, isFreePass, freePassCnt } = props;

  const itemNameStyle = {
    fontSize:
      itemName === "타지점 회원" ? "2.5rem" : itemName === "무료패스권" ? "1.875rem" : "3rem",
    color: "#ffffff",
    fontWeight: "700",
  };

  const imgStyle = {
    width: "100%",
    height: "100%",
  };

  const printImage = (name) => {
    if (name === "락커") {
      return <img style={imgStyle} src={lockerIcon} alt="locker_icon" />;
    } else if (name === "운동복") {
      return <img style={imgStyle} src={clothIcon} alt="cloth_icon" />;
    } else if (name === "GX") {
      return <img style={imgStyle} src={gxIcon} alt="gx_icon" />;
    } else if (name === "타지점 회원") {
      return <img style={imgStyle} src={linkIcon} alt="link_icon" />;
    } else if (name === "무료패스권") {
      return <img style={imgStyle} src={freePassIcon} alt="freePass_icon" />;
    }
  };

  return (
    <IconWrapper gender={gender} isUse={isUse}>
      <IconContainer>
        {printImage(itemName)}
        {isFreePass && <CountCircle>{freePassCnt}</CountCircle>}
      </IconContainer>
      <div style={itemNameStyle}>{itemName}</div>
    </IconWrapper>
  );
};
export default SubInfoItem;
