///Login
export const loadAuthInfo = (data) => {
  return {
    type: "LOAD_AUTH_INFO",
    payload: data,
  };
};

export const loadAuthInfoSuccess = (data) => {
  return {
    type: "LOAD_AUTH_INFO_DATA",
    payload: data,
  };
};

export const loadAuthInfoFail = (data) => {
  return {
    type: "LOAD_AUTH_INFO_FAIL",
    payload: data,
  };
};

export const viewAuthInfo = () => {
  return {
    type: "VIEW_AUTH_INFO",
  };
};
