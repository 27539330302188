import React from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoute from "./components/accessLib/PrivateRoute";

//로그인
import LoginPage from "views/LoginPage/LoginPage";

//메인
import Main from "views/Main";

//에러
import NotFound from "views/NotFound/NotFound";

const RouteSwitch = () => {
  return (
    <Routes>
      {/* 로그인 */}
      <Route path="/login/:branchId" element={<LoginPage />} />

      {/* 메인 */}
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Main />} />
      </Route>

      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteSwitch;
