import styled from "styled-components";

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 8%;
`;

export const WelcomeTextBox = styled.div`
  width: 100%;
  color: #ffffff;
  font-weight: 600;
  font-size: 3rem;
  text-align: left;
`;
export const NormalTextBox = styled.div`
  font-weight: 700;
  color: #ffffff;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  min-height: 268px;
  width: 17.5%;
  min-width: 224px;
  border-radius: 32px;
  border: ${(props) => (props.gender === "남" ? "1px solid #6D9FFF" : "1px solid #e87eff")};
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  box-sizing: border-box;
  padding: 2rem 0.84rem;
  filter: ${(props) => (!props.isUse ? "blur(10px)" : "null")};
  opacity: ${(props) => (props.isUse ? "1" : "0.7")};
`;

export const IconContainer = styled.div`
  position: relative;
  width: 19vh;
  min-width: 140px;
  height: 19vh;
  min-height: 140px;
`;
export const SubInfoWrapper = styled.div`
  width: 100%;
  height: 37.2vh;
  min-height: 268px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
`;

export const CountCircle = styled(FlexCenter)`
  position: absolute;
  top: 77%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #086bff;
  border-radius: 50%;
  width: 34%;
  min-width: 48px;
  height: 34%;
  min-height: 48px;

  // 안에 들어갈 font 관련
  color: white;
  font-size: 2.25rem;
`;

export const FooterInfoBox = styled(FlexCenter)`
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-weight: 700;
  font-size: 4rem;
  text-align: center;
`;
