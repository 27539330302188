import React, { createContext, useContext, useState } from "react";
// import ReactDOM from "react-dom";
import * as ReactDOMClient from "react-dom/client";
import "./index.css";
import App from "./App";

import { cookieManager } from "lib/cookieManager";
import { HttpInstance } from "lib/HttpLib";

// Context 생성
export const AuthContext = createContext(null);
export const useAuth = () => useContext(AuthContext);

const AppWrapper = () => {
  const [isLogin, setIsLogin] = useState(cookieManager.isLogin());

  const authInfo = {
    isLogin,
    getTokens: cookieManager.getTokens(),
    login: (tokens) => {
      cookieManager.setTokens(tokens);
      HttpInstance.defaults.headers.common["Authorization"] = `Bearer ${tokens.access}`;
      HttpInstance.defaults.headers.common["Refresh"] = `Bearer ${tokens.refresh}`;
      setIsLogin(true);
    },
    logout: () => {
      cookieManager.removeTokens();
      setIsLogin(false);
    },
  };

  return (
    <AuthContext.Provider value={authInfo}>
      <App />
    </AuthContext.Provider>
  );
};

const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(<AppWrapper />);
