import React, { useState, useCallback } from "react";
import { Button } from "@mui/material";
import LoginTextField from "components/LabelInput/LoginTextField";

const LoginForm = ({ setLoginInfo }) => {
  const [id, setId] = useState({ text: "", valid: true });
  const [password, setPassword] = useState({ text: "", valid: true });

  const idChangeHandler = useCallback((e) => setId({ ...id, text: e.target.value }), [id]);

  const passwordChangeHandler = useCallback(
    (e) => setPassword({ ...password, text: e.target.value }),
    [password],
  );

  const idValid = useCallback(() => {
    if (!id.text) {
      setId({ ...id, valid: false });
      return false;
    }
    setId({ ...id, valid: true });
    return true;
  }, [id]);

  const passwordValid = useCallback(() => {
    if (!password.text) {
      setPassword({ ...password, valid: false });
      return false;
    }
    setPassword({ ...password, valid: true });
    return true;
  }, [password]);

  const submitClickHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (idValid() && passwordValid()) {
        setLoginInfo({ id: id.text, password: password.text });
      }
    },
    [id, password],
  );

  const loginBtnStyle = {
    border: "1px solid #FFFFFF",
    borderRadius: "10px",
    color: "#ffffff",

    fontWeight: "500",
    fontSize: "20px",
    width: "400px",
    height: "32px",
    padding: "20px",
    marginLeft: "75px",
  };
  return (
    <form onSubmit={submitClickHandler} style={{ width: "100%" }}>
      <div
        style={{
          margin: "4.5rem auto 0 auto",
          width: "80%",
          // border: "1px solid yellow",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LoginTextField
          labelText="아이디"
          labelColor="#ffffff"
          bgColor="none"
          border="1px solid #fff"
          textColor="#fff"
          onChange={idChangeHandler}
          onBlur={idValid}
          error={!id.valid}
        />
        <LoginTextField
          marginTop="1.2rem"
          password
          // autoComplete="current-password"
          labelText="비밀번호"
          labelColor="#ffffff"
          bgColor="none"
          border="1px solid #fff"
          textColor="#fff"
          onChange={passwordChangeHandler}
          onBlur={passwordValid}
          error={!password.valid}
        />
      </div>
      <div
        style={{
          marginTop: "3rem",
          // border: "1px solid green",
          margin: "3rem auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button type="submit" style={loginBtnStyle}>
          로그인
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
