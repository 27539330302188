import styled from "styled-components";

export const StyledInput = styled.input`
  width: 400px;
  padding: 12px;
  margin-bottom: 10px;
  background-color: transparent;
  color: white;
  border: 1.5px solid white;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
  transition: all 0.1s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.75);
  }

  &:focus {
    border-color: rgba(234, 198, 255, 0.5);
  }
`;

export const LoginButton = styled.button`
  width: 400px;
  padding: 12px;
  background-color: transparent;
  color: white;
  border: 1.5px solid white;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s ease;
  margin-top: 2rem;

  &:hover {
    background-color: rgba(234, 198, 255, 0.1);
  }
`;
