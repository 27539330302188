import axios from "axios";
import { cookieManager } from "./cookieManager";

export const HttpInstance = axios.create({
  timeout: 40000,
  headers: {
    "X-Product": "erp",
    "Public-Ip": sessionStorage.getItem("Public-Ip"),
  },
});

export const GetPublicIpAddr = async () => {
  try {
    var response = await fetch("https://api.ip.pe.kr/");
    if (response.ok) {
      const ipAddr = await response.text();
      sessionStorage.setItem("Public-Ip", ipAddr);
    }
  } catch (error) {
    var response2 = await fetch("https://icanhazip.com/");
    if (!response2.ok) {
      return;
    } else {
      const ipAddr = await response2.text();
      sessionStorage.setItem("Public-Ip", ipAddr);
    }
  }
};

export const RefreshPublicIpHeader = async () => {
  const publicIp = sessionStorage.getItem("Public-Ip");
  if (!publicIp) {
    await GetPublicIpAddr();
  }
  if (!HttpInstance.defaults.headers["Public-Ip"]) {
    HttpInstance.defaults.headers["Public-Ip"] = sessionStorage.getItem("Public-Ip");
  }
};
// 라이브러리의 timeout 기본 값을 2.5초로 재 정의하여
// 인스턴스의 모든 요청은 2.5초 간만 대기 후 타임아웃 처리합니다.
// httpInstance.defaults.timeout = 3500;
HttpInstance.interceptors.request.use(async (config) => {
  RefreshPublicIpHeader();
  return config;
});

HttpInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  function (error) {
    const originalRequest = error.config;
    // Do something with response error

    if (error.response.status === 401) {
      originalRequest._retry = true;

      RefreshPublicIpHeader();

      const retryOrigReq = new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"] =
          HttpInstance.defaults.headers.common["Authorization"];
        axios
          .post(process.env.REACT_APP_AUTH_SERVER + "/token/renew", {
            refresh_token: HttpInstance.defaults.headers.common["Refresh"],
          })
          .then((resp) => {
            if (resp.error !== false) {
              HttpInstance.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${resp.data.tokens.access}`;
              HttpInstance.defaults.headers.common[
                "Refresh"
              ] = `Bearer ${resp.data.tokens.refresh}`;
            }
            originalRequest.headers.Authorization = `Bearer ${resp.data.tokens.access}`;
            originalRequest.headers.Refresh = `Bearer ${resp.data.tokens.refresh}`;

            cookieManager.setTokens(resp.data.tokens);
            resolve(axios(originalRequest));
          })
          .catch((error) => {
            console.log("error + " + error);
            const cookies = document.cookie.split("; ");
            const expiration = "Sat, 01 Jan 1972 00:00:00 GMT";
            for (let i = 0; i < cookies.length; i++) {
              document.cookie = cookies[i].split("=")[0] + "=; expires=" + expiration;
            }
          });
      });
      return retryOrigReq;
    } else {
      return Promise.reject(error);
    }
  },
);
