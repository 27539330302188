import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import RouteSwitch from "./RouteSwitch";
import AlertPopupGlobal from "components/Popup/AlertPopupGlobal";

import { useDispatch, useSelector } from "react-redux";
import { RefreshPublicIpHeader } from "views/LoginPage/IpAddrAuth";

function App() {
  const alertModal = useSelector((state) => state.alert_state);

  useEffect(() => {
    RefreshPublicIpHeader();
  }, []);

  return (
    <div className="App">
      <AlertPopupGlobal params={alertModal.stores} />
      <>
        <BrowserRouter>
          <RouteSwitch></RouteSwitch>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
