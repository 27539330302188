import { useMemo } from "react";
import { WelcomeWrapper } from "./style";

const Welcome = ({ branchId }) => {
  const branchImg = useMemo(() => {
    return `${process.env.REACT_APP_S3_HOST}/${branchId}/img.png`;
  }, [branchId]);

  return (
    <WelcomeWrapper>
      <img src={branchImg} alt="지점메인" height="100%" />
    </WelcomeWrapper>
  );
};

export default Welcome;
