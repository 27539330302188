import styled from "styled-components";

export const PageWrapper = styled.div`
  height: 100vh;
  min-height: 720px;
  width: 100vw;
  min-width: 1280px;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: black;
`;

export const InfoTextContainer = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 25.5%;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #ffffff;
`;

export const WelcomeWrapper = styled.div`
  height: 100vh;
  min-height: 720px;
  display: flex;
  justify-content: center;
  background-color: black;
`;
