import female from "../assets/female.jpg";
import male from "../assets/male.jpg";
import FooterInfo from "../components/FooterInfo";
import TitleInfo from "../components/TitleInfo";
import SubInfo from "../components/SubInfo";
import { useEffect } from "react";
import { PageWrapper } from "./style";
const Member = (props) => {
  const {
    setViewState,
    isLink,
    linkCnt,
    isFreePass,
    freePassCnt,
    infoState,
    infoState: {
      membership_info: { to_date, remain_day },
      member_info: { member_name, gender },
    },
  } = props;

  // 마운트 7초뒤 -> 다시 main
  useEffect(() => {
    const timeId = setTimeout(() => {
      setViewState("main");
    }, 7000);
    return () => {
      clearTimeout(timeId);
    };
  }, [infoState]);
  return (
    <PageWrapper>
      <img
        src={gender === "여" ? female : male}
        alt={gender === "여" ? "female_bg" : "male_bg"}
        width="100%"
        height="100%"
        style={{ position: "relative" }}
      />
      <TitleInfo memberName={member_name} toDate={to_date} isFreePass={isFreePass} />

      <div style={{ position: "absolute", top: "40%", width: "100%" }}>
        <SubInfo
          isLink={isLink}
          linkCnt={linkCnt}
          isFreePass={isFreePass}
          freePassCnt={freePassCnt}
          infoState={infoState}
        />
      </div>
      <div style={{ position: "absolute", bottom: "0", height: "11.5%" }}>
        <FooterInfo
          isLink={isLink}
          linkCnt={linkCnt}
          isFreePass={isFreePass}
          remainDay={remain_day}
        />
      </div>
    </PageWrapper>
  );
};
export default Member;
