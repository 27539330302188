import SubInfoItem from "./SubInfoItem";
import { SubInfoWrapper } from "./style";
const SubInfo = (props) => {
  const {
    isLink,
    linkCnt,
    isFreePass,
    freePassCnt,
    infoState: {
      member_info: { gender },
      membership_info: { use_gx, use_cloth, use_locker },
    },
  } = props;

  const renderIcons = () => {
    if (isFreePass) {
      return (
        <SubInfoItem
          itemName="무료패스권"
          gender={gender}
          isFreePass={isFreePass}
          freePassCnt={freePassCnt}
          isUse={isFreePass}
        />
      );
    } else {
      return (
        <>
          {isLink && (
            <SubInfoItem itemName="타지점 회원" gender={gender} isUse={isLink} linkCnt={linkCnt} />
          )}
          <SubInfoItem itemName="락커" gender={gender} isUse={use_locker} />
          <SubInfoItem itemName="운동복" gender={gender} isUse={use_cloth} />
          <SubInfoItem itemName="GX" gender={gender} isUse={use_gx} />
        </>
      );
    }
  };

  return <SubInfoWrapper>{renderIcons()}</SubInfoWrapper>;
};

export default SubInfo;
