///Login
export const globalAlertOn = (param) => {
  return {
    type: "alert/GLOBAL_STATE/ON",
    payload: param,
  };
};

export const globalAlertOff = (param) => ({
  type: "alert/GLOBAL_STATE/OFF",
  payload: param,
});
