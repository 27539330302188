import { WelcomeWrapper } from "./style";

const Welcome = ({ branchId }) => {
  const branchImg = `${process.env.REACT_APP_S3_HOST}/${branchId}/img.png`;

  return (
    <WelcomeWrapper>
      <img src={branchImg} alt="지점메인" height="100%" />
    </WelcomeWrapper>
  );
};

export default Welcome;
