import React, { useState, useEffect, useCallback, useContext } from "react";
import Login from "./LoginTemplete";
import { useNavigate, useParams } from "react-router-dom";
import { HttpInstance, SetAuthTokens } from "../../lib/HttpLib";
import { setAuthInfoStores, getAuthInfoStores } from "../../modules/actions/Auth/index";
import { globalAlertOn } from "modules/actions/Alert";

import { useDispatch, useSelector } from "react-redux";

const LoginPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const result = useSelector((state) => state.auth_info);
  const dispatch = useDispatch();
  const [loginInfo, setLoginInfo] = useState({ id: "", password: "" });

  const resetLoginInfo = () => {
    setLoginInfo({ id: "", password: "" });
  };

  const goToMain = () => {
    navigate("/", { state: { branchId: params.branchId } });
  };
  const onBtnOk = () => {
    // console.log("onBtnOk");
  };

  const axiosLogin = useCallback(async (body) => {
    const data = {
      user_id: body.id,
      password: body.password,
    };

    HttpInstance.post(process.env.REACT_APP_AUTH_SERVER + "/user/sign/in", data)
      .then((response) => {
        let apiRes = response.data;
        if (!apiRes.error) {
          const accessToken = apiRes.tokens.access;
          const refreshToken = apiRes.tokens.refresh;

          dispatch(setAuthInfoStores(apiRes.tokens));

          HttpInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
          HttpInstance.defaults.headers.common["Refresh"] = `Bearer ${refreshToken}`;

          resetLoginInfo();
          goToMain();
        } else {
          dispatch(
            globalAlertOn({
              show: true,
              titleText: "[로그인] - 알림",
              bodyText: "아이디 또는 패스워드를 확인해주세요",
              // onBtnOk: () => () => onBtnOk,
              onBtnOk: () => onBtnOk(),
            }),
          );
        }
      })
      .catch((error) => {
        // ... 에러 처리
        dispatch(
          globalAlertOn({
            show: true,
            titleText: "[로그인] - 알림",
            bodyText: error.response.data.msg,
            // onBtnOk: () => () => onBtnOk,
            onBtnOk: () => onBtnOk(),
          }),
        );
      });
  }, []);

  useEffect(() => {
    if (loginInfo.id && loginInfo.password) {
      axiosLogin({ ...loginInfo });
    }
  }, [loginInfo]);
  return (
    <>
      <Login setLoginInfo={setLoginInfo} />
    </>
  );
};

export default LoginPage;
