import { auth_info, state_auth_info } from "./authReducer";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "cookies-js";

import { alert_state } from "./alert_reducer";

const persistConfig = {
  key: "root",
  // localStorage에 저장합니다.
  storage: new CookieStorage(Cookies),
  // storage: sessionStorage,
  // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
  // whitelist: ["auth_info", "state_auth_info", "global_auth"],
  whitelist: ["auth_info", "state_auth_info", "selected_branch"],
  // blacklist -> 그것만 제외합니다
};

const rootReducer = combineReducers({
  auth_info,
  state_auth_info,
  alert_state,
});

// export default rootReducer;
export default persistReducer(persistConfig, rootReducer);
