const initialStete = {
  stores: null,
};
export const auth_info = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_AUTH_INFO_DATA": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_AUTH_INFO_FAIL":
    default:
      return state;
  }
};

export const state_auth_info = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_AUTH_BASE": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_AUTH_BASE_FAIL":
    default:
      return state;
  }
};
