import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { getAuthInfoStores } from "../../modules/actions/Auth/index";
import { SetAuthTokens } from "../../lib/HttpLib";

const PrivateRoute = ({ auth_info }) => {
  const auth = () => {
    if (auth_info.stores === undefined) {
      console.log("store undefined");

      return false;
    }
    if (auth_info.stores === null) {
      console.log("store null", auth_info);
      return false;
    }
    if (auth_info.stores.auth_info !== undefined) {
      console.log("store  auth_info undefined");
      if (auth_info.stores.auth_info.stores === null) {
        console.log("store  auth_info.stores null");
        return false;
      }
    }

    SetAuthTokens(auth_info.stores);

    return true;
  }; // determine if authorized, from context or however you're doing it

  const retAuth = auth();

  return retAuth ? <Outlet /> : <Navigate to="/login" />;
};

// export default PrivateRoute;
function mapStateToProps(state) {
  return {
    auth_info: state.auth_info,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAuthStore: () => dispatch(getAuthInfoStores()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
