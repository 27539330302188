import { put, select } from "redux-saga/effects";
import { authAction } from "../actions/Auth/index";

export function* setAuthInfo(param) {
  try {
    // console.log("setAuthInfo  ->  ");
    // console.log(param.payload);
    // const { data } = yield call(get_api.getNotice, param);
    yield put({
      type: "LOAD_AUTH_INFO_DATA",
      payload: param.payload,
    });
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(authAction.loadAuthInfoFail(error));
  }
}

export const AUTH_PRINT_STATE = "VIEW_AUTH_INFO";

//액션생성함수
export const printState = () => ({ type: AUTH_PRINT_STATE });

//saga 리덕스 스토어 조회
export function* authSagaInfo() {
  const state = yield select((state) => state.auth_info);

  return state;
}
