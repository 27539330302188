import React, { useState } from "react";
import { LoginButton, StyledInput } from "./style";

const LoginForm = ({ setLoginInfo }) => {
  const [submitInfo, setSubmitInfo] = useState({
    id: "",
    password: "",
  });

  const submitClickHandler = (e) => {
    e.preventDefault();
    setLoginInfo(submitInfo);
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setSubmitInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={submitClickHandler} style={{ width: "100%" }}>
      <div
        style={{
          margin: "4.5rem auto 0 auto",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StyledInput
          type="text"
          name="id"
          value={submitInfo.id}
          onChange={onChangeHandler}
          placeholder="아이디"
          autoComplete="username"
        />
        <StyledInput
          type="password"
          name="password"
          value={submitInfo.password}
          onChange={onChangeHandler}
          placeholder="비밀번호"
          autoComplete="current-password"
        />
        <LoginButton type="submit">로그인</LoginButton>
      </div>
    </form>
  );
};

export default LoginForm;
