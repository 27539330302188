/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import blockedEnter from "../assets/blockedEnter.jpg";
import { InfoTextContainer, PageWrapper } from "./style";

const BlockedEnter = (props) => {
  const { setViewState, infoState } = props;
  const [infoText, setInfoText] = useState("");

  useEffect(() => {
    changeInfoText();
    const timeId = setTimeout(() => {
      setViewState("main");
    }, 7000);
    return () => {
      clearTimeout(timeId);
    };
  }, [infoState]);

  const changeInfoText = () => {
    switch (infoState.invalid_reason) {
      // 회원 오류
      case "타지점 출입 가능 횟수를 모두 소진하였습니다":
        setInfoText("타지점 이용 횟수 소진으로 출입할 수 없습니다.");
        return;
      case "유효한 회원권이 없습니다":
        setInfoText("이용할 수 있는 회원권이 없습니다.");
        return;
      case "지점 운영 시간이 아닙니다":
        setInfoText("운영 시간이 아닙니다.");
        return;
      case "유효한 회원권이 없고, 무료 패스권을 모두 소진하였습니다":
        setInfoText("무료패스권 소진으로 출입할 수 없습니다.");
        return;
      case "출입 수단 타입에 오류가 있습니다":
        setInfoText("출입에 실패했습니다.\nQR 코드를 재발급하여 다시 시도해 주세요.");
        return;
      // 직원 오류
      case "소속되지 않은 지점입니다":
        setInfoText("근무 지점이 아닙니다.");
        return;
      // 회원, 직원 오류
      case "카드 상태 오류, 상태: 정지":
        setInfoText("정지된 카드입니다.\n인포 데스크에 문의해 주시기 바랍니다.");
        return;
      case "카드 상태 오류, 상태: 대기":
        setInfoText("발급되지 않은 카드입니다.\n인포 데스크에 문의해 주시기 바랍니다.");
        return;
      case "출입 방법이 '카드'로 설정되어 있지 않습니다":
        setInfoText("출입이 중단된 카드입니다.\n앱으로 출입해 주세요.");
        return;
      case "출입카드 정보에 오류가 있습니다":
        setInfoText("출입에 실패했습니다.\n문제가 지속될 경우, 인포 데스크에 문의해 주세요.");
        return;
      case "QR 코드 오류, 상태":
        setInfoText("출입에 실패했습니다.\nQR 코드를 재발급하여 다시 시도해 주세요.");
        return;
      case "QR 코드의 유효 시간이 지났습니다":
        setInfoText("QR 코드의 유효시간이 지났습니다.\n재발급하여 다시 시도해 주세요.");
        return;
      case "출입 방법이 '앱'으로 설정되어 있지 않습니다":
        setInfoText("QR 코드로 출입하려면,\n출입 방식을 ‘앱’으로 변경해야 합니다.");
        return;
      case "QR 코드 정보에 오류가 있습니다":
        setInfoText("출입에 실패했습니다.\nQR 코드를 재발급하여 다시 시도해 주세요.");
        return;
      default:
        setInfoText("출입에 실패했습니다.\n인포 데스크에 문의해 주시기 바랍니다.");
        return;
    }
  };

  return (
    <PageWrapper>
      <img src={blockedEnter} alt="출입제한" width="100%" height="100%" />
      <InfoTextContainer>
        <div style={{ fontSize: "3.5rem", whiteSpace: "pre-line", lineHeight: "140%" }}>
          {infoText}
        </div>
      </InfoTextContainer>
    </PageWrapper>
  );
};

export default BlockedEnter;
