import { HttpInstance } from "lib/HttpLib";

export const GetPublicIpAddr = async () => {
  try {
    var response = await fetch("https://api.ip.pe.kr/");
    if (response.ok) {
      const ipAddr = await response.text();
      sessionStorage.setItem("Public-Ip", ipAddr);
    }
  } catch (error) {
    var response2 = await fetch("https://icanhazip.com/");
    if (!response2.ok) {
      return;
    } else {
      const ipAddr = await response2.text();
      sessionStorage.setItem("Public-Ip", ipAddr);
    }
  }
};

export const RefreshPublicIpHeader = async () => {
  const publicIp = sessionStorage.getItem("Public-Ip");
  if (!publicIp) {
    await GetPublicIpAddr();
  }
  if (!HttpInstance.defaults.headers["Public-Ip"]) {
    HttpInstance.defaults.headers["Public-Ip"] = sessionStorage.getItem("Public-Ip");
  }
};