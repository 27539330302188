import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "components/Button/Button";



const PopupAlert = (props) => {
  const {
    isOpen,
    onClose,
    Content,
    width,
    reqConfirm,
  } = props;


  const contentsStyle = {

    fontSize: props.fontSize ? props.fontSize : "1rem",
    fontWeight: props.fontWeight ? props.fontWeight : "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: props.color ? props.color : "#000",
    backgroundColor: '#F3F5F7',
    padding: '2.5rem 1.78rem 1.875rem 1.78rem'
  };


  return (
    <div >
      <Dialog
        PaperProps={{
          style: { borderRadius: '16px' }
        }}
        open={isOpen}
        // onClose={reqConfirm ? "" : onClose}
        transitionDuration={{ enter: 100, exit: 100 }}
        fullWidth
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (props.onBtnYesCallback) props.onBtnYesCallback();
            else if (props.onBtnOkCallback) props.onBtnOkCallback();
          }
        }}
      >



        <DialogContent style={contentsStyle}>
          <div>{Content}</div>
          <div
            style={{
              // border: '1px solid red',
              display: "flex",
              gap: '0.625rem',
              justifyContent: 'center',
              alignItems: "center",
              marginTop: "1.2rem",
              width: width ? width : "",
            }}
          >
            {props.onBtnOkCallback !== undefined ? (
              <Button btnName='확인' theme='white' onClick={props.onBtnOkCallback} />
            ) : (
              ""
            )}

            {props.onBtnNoCallback !== undefined ? (
              <Button btnName='아니오' theme='white' onClick={props.onBtnNoCallback} />
            ) : (
              ""
            )}

            {props.onBtnYesCallback !== undefined ? (
              <Button btnName='예' theme='blue' onClick={props.onBtnYesCallback} />
            ) : (
              ""
            )}
          </div>
        </DialogContent>

      </Dialog>
    </div>
  );
};

PopupAlert.defaultProps = {};

export default PopupAlert;
