import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextFieldComp from "@mui/material/TextField";
import Label from "./Label";
import { checkNumber } from "components/CommonLib/CommonLib";
import { InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";




const StyledTextField = styled(TextFieldComp)({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});




const LoginTextField = (props) => {
  const [value, setValue] = useState(props.defaultValue);

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      if (props.isNumber) {
        setValue(props.defaultValue || "0");
      } else {
        setValue(props.defaultValue);
      }
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.inputMode === "numeric") {
      setValue(props.value);
    }
  }, [props.value]);

  const cNumberCheck = (e) => {
    if (props.isNumber !== undefined && props.isNumber === true) {
      if ("" === e.target.value) {
        if (props.minValue) {
          setValue(props.minValue);
          return props.minValue;
        } else {
          setValue(0);
          return 0;
        }
      } else if (isNaN(e.target.value)) {
        ///숫자여야 하는데 문자인 경우
        if (props.minValue) {
          setValue(props.minValue);
          e.target.value = props.minValue;
          if (props.onChange === undefined) {
            return props.minValue;
          }
        } else {
          setValue("0");
          e.target.value = "0";
          if (props.onChange === undefined) {
            return 0;
          }
        }
      } else {
        if (props.onChange === undefined) {
          return Number(e.target.value);
        }
      }
    }

    return e.target.value;
  };

  const cOnChange = (e) => {
    if (props.maxValue === 0) {
      setValue(0);
      e.target.value = 0;
    }

    if (props.maxValue && e.target.value > props.maxValue) {
      setValue(props.maxValue);
      e.target.value = props.maxValue;
    } else if (props.minValue && e.target.value < props.minValue) {
      setValue(props.minValue);
      e.target.value = props.minValue;
    } else {
      setValue(e.target.value);
    }

    if (props.onChangeCallback !== undefined) {
      props.onChangeCallback(cNumberCheck(e));
    }
  };
  const handleStringNumber = (val) => {
    if (checkNumber(val) || val === "") {
      if (props.maxValue && val > props.maxValue) {
        setValue(props.maxValue);
        val = props.maxValue;
      } else if (props.minValue && val < props.minValue) {
        setValue(props.minValue);
        val = props.minValue;
      } else {
        setValue(val);
        props.onChangeCallback(val);
      }
    } else {
      props.onChangeCallback(value);
    }
  };


  const hStyle = {
    boxSizing: 'border-box',
    width: props.fullWidth ? "100%" : null,
    marginTop: props.marginTop,
    display: 'flex',
    alignItems: 'center',

  };

  const fontStyle = {

    fontSize: '20px',
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: props.labelColor,
  };

  const labelStyle = {
    width: '70px',
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    ...fontStyle,
  };

  const textBoxStyle = {

    width: '400px',
    height: "2.75rem",
    marginRight: props.textMarginRight,
    borderRadius: "5px",
    border: props.border,
    backgroundColor: props.bgColor ? props.bgColor : '#fff',

    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: props.textColor ? props.textColor : "#000",
    opacity: props.opacity,
    paddingRight: '0rem',


  };

  const textBoxStyleDisabled = {
    width: props.textBoxWidth,
    height: "2.75rem",
    marginRight: props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#ebebeb",

    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    paddingRight: '0rem'
  };




  return (
    <Box style={props.style || hStyle}>

      <div style={labelStyle}>
        {props.labelText}
      </div>
      <StyledTextField
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#555",
          },

        }}
        fullWidth={props.fullWidth}
        inputProps={{
          style: { textAlign: props.isNumber ? "right" : "left" },
          maxLength: props.maxLength,

        }}
        InputProps={{
          readOnly: props.readonly,
          endAdornment: (
            <InputAdornment position="end">
              <Label labelText={props.endAdornment} color="#777" fontSize="0.9rem" />
            </InputAdornment>
          ),
          style: props.disabled === true ? textBoxStyleDisabled : textBoxStyle,


        }}
        InputLabelProps={{ style: { fontSize: "0.8rem" } }}
        id={props.id}
        placeholder={props.placeholder}
        // label={props.placeHolder}
        disabled={props.disabled}
        type={props.password ? "password" : ""}
        size="small"
        inputRef={props.inputRef}
        onKeyPress={props.onKeyPress}
        value={value || ""}
        name={props.name}
        onChange={(e) => {
          if (props.isStringNumber) {
            handleStringNumber(e.target.value);
          } else {
            cOnChange(e);
            if (props.onChange) {
              if (props.isNumber) {
                if (e.target.value === "") {
                  if (props.minValue) {
                    setValue(props.minValue);
                    e.target.value = props.minValue;
                  } else {
                    setValue(0);
                    e.target.value = "0";
                  }
                }
              }
              cNumberCheck(e);
              props.onChange(e);
            }
          }
        }}
        onKeyDown={props.onKeyDown}
        error={props.required && (value === undefined || value === "")}
        onBlur={(e) => {
          props.onBlur(e)
        }}
        autoFocus={props.autoFocus}
        helperText={props.helperText}
        label={props.label}
        autoComplete={props.autoComplete ? props.autoComplete : "off"}
      />
    </Box>
  );
};

export default React.memo(LoginTextField);
