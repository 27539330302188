/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from "react";
// STYLESHEET

import none from "../../assets/404.png";

const NotFound = () => {
  return (
    <div style={{ width: "100%", height: "100vh", overflowY: "hidden" }}>
      <a
        href="https://www.freepik.com/free-vector/gradient-particles-background_4332084.htm"
        style={{
          textDecoration: "none",
          color: "#1E2C68",
          position: "absolute",
          top: "1.5rem",
          right: "1rem",
          fontSize: "0.8rem",
        }}
      >
        Image by pikisuperstar
      </a>
      <img src={none} alt="notFound" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};
// class NotFound extends Component {
//   render() {
//     return (
//       <div>
//         <img src={none} className="App-logo" alt="logo" style={{ border: "3px solid red" }} />
//       </div>
//     );
//   }
// }
export default NotFound;
