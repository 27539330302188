import React, { useState, useEffect } from "react";
import Login from "./LoginTemplete";
import { useNavigate, useParams } from "react-router-dom";
import { HttpInstance } from "../../lib/HttpLib";
import { useAuth } from "index";
import PopupAlert from "components/Popup/PopupAlert";

const LoginPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { login } = useAuth();
  const [showPopup, setShowPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loginInfo, setLoginInfo] = useState({ id: "", password: "" });

  const resetLoginInfo = () => {
    setLoginInfo({ id: "", password: "" });
  };

  const goToMain = () => {
    navigate("/", { state: { branchId: params.branchId } });
  };

  const axiosLogin = async (body) => {
    const data = {
      user_id: body.id,
      password: body.password,
    };

    HttpInstance.post(process.env.REACT_APP_AUTH_SERVER + "/user/sign/in", data)
      .then((response) => {
        const apiRes = response.data;
        if (!apiRes.error) {
          login(apiRes.tokens);
          resetLoginInfo();
          goToMain();
        } else {
          setAlertMessage("아이디 또는 패스워드를 확인해주세요");
          setShowPopup(true);
        }
      })
      .catch((error) => {
        setAlertMessage(error.response?.data?.msg || "로그인 중 오류가 발생했습니다");
        setShowPopup(true);
      });
  };

  useEffect(() => {
    if (loginInfo.id && loginInfo.password) {
      axiosLogin({ ...loginInfo });
    }
  }, [loginInfo]);
  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setShowPopup(false);
        }
      }}
    >
      {showPopup && <PopupAlert message={alertMessage} onClose={() => setShowPopup(false)} />}
      <Login setLoginInfo={setLoginInfo} />
    </div>
  );
};

export default LoginPage;
