import { viewAuthInfo, loadAuthInfo, loadAuthInfoSuccess, loadAuthInfoFail } from "./auth_action";

export const setAuthInfoStores = (info) => loadAuthInfo(info);

export const getAuthInfoStores = () => viewAuthInfo();

export const authAction = {
  loadAuthInfoSuccess,
  loadAuthInfoFail,
};
