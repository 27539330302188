/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Member from "./Member";
import Welcome from "./Welcome";
import TresPassing from "./TresPassing";
import BlockedEnter from "./BlockedEnter";

const Main = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  // 페이지 상태 관리
  const [socketConnected, setSocketConnected] = useState(false);
  const [infoState, setInfoState] = useState({ is_member: false, member_info: { gender: "" } });
  const [isLink, setIsLink] = useState(false); // 타지점 출입 여부
  const [linkCnt, setLinkCnt] = useState(0); // 타지점 출입 가능 횟수
  const [isFreePass, setIsFreePass] = useState(false); // 프리패스 출입 여부
  const [freePassCnt, setFreePassCnt] = useState(0); // 프리패스 출입 가능 횟수
  const [viewState, setViewState] = useState("main"); // main, member, tres, blockedEnter

  const webSocketUrl = process.env.REACT_APP_WS_SERVER;

  const ws = useRef(null);

  // 소켓 객체 생성
  useEffect(() => {
    initConnect();
  }, []);

  // 소켓 연결되면 지점과 바로 연결되도록!
  useEffect(() => {
    if (socketConnected) {
      connect();
    }
  }, [socketConnected]);

  const initConnect = () => {
    // 1. 로그인 정보 확인
    if (!state) {
      navigate("/error");
    }

    // 2. 소켓상태 확인
    if (!ws.current) {
      // 소켓없는 상태 -> 소켓과 연결필요
      ws.current = new WebSocket(webSocketUrl);
      ws.current.onopen = () => {
        console.log("연결 완료");
        ws.current.send(
          JSON.stringify({
            conn: true,
            branch_info: {
              branch_id: state.branchId,
            },
          }),
        );
        setSocketConnected(true);
      };
    } else {
      // 연결된 소켓 있는상태
      if (!socketConnected) {
        setSocketConnected(true);
      }
      connect();
    }
  };

  const connect = () => {
    ws.current.onclose = (error) => {
      console.log(error, "연결이 끊어짐");
      console.log(error, "재연결중 ing...");
      setTimeout(() => {
        reconnect();
      }, 2000);
      setSocketConnected(false);
    };
    ws.current.onerror = (error) => {
      console.log(error, "에러 발생");
      ws.current.close();
    };

    ws.current.onmessage = (msg) => {
      //웹소켓 서버에서 받은 데이터
      const data = JSON.parse(msg.data);
      resetState();
      setInfoState(data);
      checkAccessMember(data);
    };
  };

  const checkAccessMember = (data) => {
    // 1. 무단출입 감지
    if (data.is_trespass) {
      setViewState("tres");
      return;
    }
    // 2. 회원인지 직원인지 확인
    if (data.is_member) {
      // 3. 회원 - 출입 유효인지 아닌지 확인
      if (data.verify) {
        // 3-1. 회원 - 무료패스권 출입인지 확인
        if (data.qualification === "free_pass") {
          setIsFreePass(true);
          setFreePassCnt(data.remained_free_pass_cnt);
          setViewState("member");
          return;
        }
        // 3-2. 회원 - 타지점 출입인지 확인
        if (data.branch_info.branch_id !== state.branchId) {
          setIsLink(true);
          setLinkCnt(data.remained_link_cnt);
        }
        // 3-3. 회원 - 유효한 출입
        setViewState("member");
        return;
      } else {
        // * verify는 출입이 됐으면 무조건 true이다.
        // 3-4. 회원 - 유효 X (invaild_reason에 따라 msg만 띄워주면 된다)
        setViewState("blockedEnter");
      }
    } else {
      // 4. 직원 or 지점
      if (data.verify) {
        // 4-1. 직원 - 유효 O
        setViewState("main");
      } else {
        // 4-2. 직원 - 유효 X
        setViewState("blockedEnter");
      }
    }
  };

  const reconnect = () => {
    if (ws.current) {
      ws.current = new WebSocket(webSocketUrl);
      ws.current.onopen = () => {
        console.log("재연결 완료");
        ws.current.send(
          JSON.stringify({
            conn: true,
            branch_info: {
              branch_id: state.branchId,
            },
          }),
        );
        setSocketConnected(true);
      };
    } else {
      //소켓이 없음 -> 다시 처음으로
      initConnect();
    }
  };

  // 페이지 상태 초기화
  const resetState = () => {
    setIsLink(false);
    setLinkCnt(0);
    setIsFreePass(false);
    setFreePassCnt(0);
  };

  const viewRender = () => {
    switch (viewState) {
      case "main":
        return <Welcome branchId={state.branchId} />;
      case "member":
        return (
          <Member
            setViewState={setViewState}
            infoState={infoState}
            isLink={isLink}
            linkCnt={linkCnt}
            isFreePass={isFreePass}
            freePassCnt={freePassCnt}
          />
        );
      case "tres":
        return <TresPassing setViewState={setViewState} />;
      case "blockedEnter":
        return <BlockedEnter setViewState={setViewState} infoState={infoState} />;
      default:
        return <Welcome branchId={state.branchId} />;
    }
  };

  return <>{viewRender()}</>;
};

export default Main;
