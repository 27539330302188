import styled from 'styled-components';

const PopupAlert = ({ message, onClose }) => {

  return (
    <PopupBackground>
      <PopupContainer>
        <PopupContent>
          <Message>{message || '아이디 혹은 비밀번호를 확인해주세요'}</Message>
          <ConfirmButton onClick={onClose}>확인</ConfirmButton>
        </PopupContent>
      </PopupContainer>
    </PopupBackground>
  );
};

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 35px 20px 20px 20px;
  border-radius: 8px;
  min-width: 300px;
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const Message = styled.div`
  margin: 0;
  font-size: 16px;
  color: #333;
  text-align: center;
`;

const ConfirmButton = styled.button`
  padding: 8px 24px;
  background-color: #032063;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #2b4b8b;
  }
`;

export default PopupAlert;