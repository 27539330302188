import { NormalTextBox, TitleWrapper, WelcomeTextBox } from "./style";

const TitleInfo = (props) => {
  const { memberName, toDate, isFreePass } = props;

  return (
    <TitleWrapper>
      <WelcomeTextBox>환영합니다.</WelcomeTextBox>
      <div style={{ display: "flex", gap: "16px", marginTop: "0.75rem" }}>
        <NormalTextBox>
          <span style={{ fontSize: "4rem" }}>
            {memberName.length < 10 ? memberName + " " : memberName.slice(0, 9) + "... "}
          </span>
          <span
            style={{
              fontSize: "3.5rem",
              fontWeight: "500",
              color: "rgba(255, 255, 255, 0.70)",
            }}
          >
            회원님,
          </span>
        </NormalTextBox>
        <NormalTextBox>
          <span style={{ fontSize: "4rem" }}>{toDate}</span>
          <span
            style={{
              fontSize: "3.5rem",
              fontWeight: "500",
              color: "rgba(255, 255, 255, 0.70)",
            }}
          >
            {isFreePass ? "(만료)" : "까지"}
          </span>
        </NormalTextBox>
      </div>
    </TitleWrapper>
  );
};
export default TitleInfo;
