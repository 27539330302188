import { takeLatest, takeEvery, all } from "redux-saga/effects";
import { setAuthInfo, authSagaInfo, AUTH_PRINT_STATE } from "./auth_sagas";
import { fork, call, put } from "redux-saga/effects";

function* sagaOriginal() {
  yield takeLatest("LOAD_AUTH_INFO", setAuthInfo);
  yield takeLatest(AUTH_PRINT_STATE, authSagaInfo);
}

function* rootSaga() {
  yield all([
    fork(sagaOriginal), // saga1 can also yield [ fork(actionOne), fork(actionTwo) ]
  ]);
}

export default rootSaga;
