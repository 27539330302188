import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import RouteSwitch from "./RouteSwitch";
import { RefreshPublicIpHeader } from "views/LoginPage/IpAddrAuth";

function App() {
  useEffect(() => {
    RefreshPublicIpHeader();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <RouteSwitch></RouteSwitch>
      </BrowserRouter>
    </div>
  );
}

export default App;
