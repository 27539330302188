import trespassing from "../assets/tresPassing.jpg";
import { useEffect } from "react";
import { PageWrapper } from "./style";
const TresPassing = ({ setViewState }) => {
  useEffect(() => {
    const timeId = setTimeout(() => {
      setViewState("main");
    }, 7000);
    return () => {
      clearTimeout(timeId);
    };
  }, []);
  return (
    <PageWrapper>
      <img src={trespassing} alt="무단출입" width="100%" height="100%" />
    </PageWrapper>
  );
};

export default TresPassing;
